.elementOff{
 font-size:300px
}
.elementOn{
font-size:30;
color:red;
background-color:black;
}
.elementOff-text{
    color:gray;
}
.elementOn-text{
    color: black;
}
.elementOn-background{
    background-color:red
}
